import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Navbar from './navbar'

import Header from "./header"
//import "./layout.css"
import "./layout.scss"
import { isLoggedIn } from "../services/auth"

const isHeader=undefined

const Layout = ({ children }) => (
  <div>
  <StaticQuery
    
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            author
            name
          }
        }
      }
    `}
    

    render={data => {    
      const dt = new Date()     
      return(
      <>
        <Navbar isLoggedIn={isLoggedIn()} />
        {isHeader?<Header siteTitle={data.site.siteMetadata.title} />:null}
        <div className="has-navbar-fixed-top has-text-dark" 
          style={{
            margin: `0 auto`,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main className="has-text-dark">{children}</main>
          <footer className="has-text-dark" style={{marginTop:'1.45rem'}}>
            © {dt.getDate() + '/' + dt.getMonth() + '-' + dt.getFullYear()}, Built with
            {` `}
          </footer>
        </div>
      </>
    )}} 
  />

  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
