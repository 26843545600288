import React, {Component} from 'react'
import { connect } from 'react-redux';
import axios from 'axios'
import { setNavbarKey, NAVBAR_KEY_ROOTDIR, NAVBAR_KEY_SUBDIR, NAVBAR_KEY_EDIT, NAVBAR_KEY_VIEW, NAVBAR_KEY_ORDER_BY, 
  VIEW_SINGLE_COLUMN, VIEW_DOUBLE_COLUMN, VIEW_ROW, VIEW_TIMELINE,
  ORDER_BY_MDATE_ASC, ORDER_BY_MDATE_DESC, ORDER_BY_FNAME,
} from '../state/navbar';
import { Link, navigate } from 'gatsby'
import { loginLink, logout, isLoggedIn, getUser } from "../services/auth"

import imageTk from "../images/logo/TK_vit600.png";
const TEXTS = {
  LOGIN:'Login',
  LOGOUT:'Logout',
  YOU_ARE_LOGGED_IN:'You logged in as '
}


const styles = { 
  nav:{
    background:'red',
  },
  tkLogo:{
    marginTop:2,
    marginBottom:2,
    cursor:'pointer',
    willChange: 'opacity',
    transition: '1s all ease',
    cursor:'pointer',
    //filter:'invert(11%) sepia(54%) saturate(3727%) hue-rotate(303deg) brightness(89%) contrast(103%)',
    filter: 'invert(16%) sepia(35%) saturate(4052%) hue-rotate(296deg) brightness(96%) contrast(101%)'
  }
}

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const Navbar = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
      showDropdown:true,
      dirs:[]
    }
    this.setUser = this.setUser.bind(this)
    this.setNavbarKey = this.setNavbarKey.bind(this)
    this.toggleHamburger = this.toggleHamburger.bind(this)
 }

  componentDidMount() {
    const fetchData = async () => {
      let url=apiBaseUrl + '/listDirs'
      const result = await axios(url,);
      console.log('LayoutImages:url', url)  
      console.log('/listDirs: result.data', result.data)  
      const dirs = result.data.result?result.data.result:[]  
      this.setState({dirs})
    }
    fetchData()
  }  

  setUser(e, user) {
    this.setState({showDropdown:false})
    // Special directory for user tk
    const rootdir = user==='tk'?'':'users/' + user + '/images'
    this.props.dispatch(setNavbarKey(NAVBAR_KEY_ROOTDIR,  rootdir))  
    this.props.dispatch(setNavbarKey(NAVBAR_KEY_SUBDIR,  undefined))  
  }  

  setNavbarKey(key, value) {
    this.props.dispatch(setNavbarKey(key, value))  
  }

  toggleHamburger = (e) => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }



  isActiveRootdir(user) {
    if (this.props.rootdir) {
      if (this.props.rootdir == '../images' && user === 'tk') {
        return 'is-active'
      } else if (this.props.rootdir === user + '/images') {
        return 'is-active'
      }
      return ''
    }   
  }

  handleLogin = e =>  {
    e.preventDefault()
    navigate(loginLink)
  }

  handleLogout = e =>  {
    e.preventDefault()
    logout(() => navigate(`/`))
  }

  render() {
    const loggedIn = this.props.isLoggedIn
    const photoUrl = getUser().photoURL?getUser().photoURL:undefined
    const userName = getUser().displayName?getUser().displayName:getUser().email?getUser().email:JSON.stringify(getUser())
    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          {loggedIn?
            <div className={`navbar-start is-hidden-touch`} style={{marginTop:0, height:20 }}>

              <div className='navbar-brand' style={{padding:0, marginTop:0}}>
                {photoUrl?<img className='column' src={photoUrl} alt="No " style={{position:'relative', borderRadius:"50%", height:60}} />:null}
              </div>
              <div className={'navbar-item'} style={{height:60, padding:0, marginTop:0, vertialAlign:'center'}}>
                {userName?<small className='column'>{TEXTS.YOU_ARE_LOGGED_IN +  userName}</small>:null}
              </div>
            </div>
          :null}

          <div className="navbar-brand">
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={(e) => this.toggleHamburger(e)}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-left">
              <Link to="/" className="navbar-item" onClick={e => {this.setNavbarKey(NAVBAR_KEY_SUBDIR, undefined); this.setNavbarKey(NAVBAR_KEY_SUBDIR, undefined)}}>
                Home
              </Link>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link  is-arrowless">
                    Sort By
                </a>
                <div className="navbar-dropdown">
                    <a className={`navbar-item ${this.props.orderBy===ORDER_BY_MDATE_ASC?"is-active":""}`} onClick={(e) => this.setNavbarKey(NAVBAR_KEY_ORDER_BY, ORDER_BY_MDATE_ASC)}>Date (newest first)</a>
                    <a className={`navbar-item ${this.props.orderBy===ORDER_BY_MDATE_DESC?"is-active":""}`} onClick={(e) => this.setNavbarKey(NAVBAR_KEY_ORDER_BY, ORDER_BY_MDATE_DESC)}>Date (oldest first)</a>
                    <a className={`navbar-item ${this.props.orderBy===ORDER_BY_FNAME?"is-active":""}`} onClick={(e) => this.setNavbarKey(NAVBAR_KEY_ORDER_BY, ORDER_BY_FNAME)}>Filename</a>
                </div>
              </div>
              {loggedIn?
                <a className="navbar-item" onClick={(e) => this.setNavbarKey(NAVBAR_KEY_EDIT, this.props.edit?undefined:true)}>
                  {this.props[NAVBAR_KEY_EDIT]===undefined?'Edit':'Unedit'}
                </a>
              :null}  
            </div>
            {loggedIn?
              <a className="navbar-item" onClick={this.handleLogout}>
                {TEXTS.LOGOUT}
              </a>
              :
              <a className="navbar-item" onClick={this.handleLogin} >
                {TEXTS.LOGIN} 
              </a>
            }  

          </div>
        </div>
      </nav>
    )
  }
}

/*
<a className="navbar-dropdown">
{this.state.users.map(user =>
    <Link to="/page-column-view" title="Logo " className={`navbar-item ${this.isActiveRootdir(user)}`} onClick={(e) => this.setUser(e, user)}>
      {user}
    </Link>  
)}  
</a>
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link is-arrowless">
                    View
                </a>
                <a className="navbar-dropdown">
                    <Link to="/page-column-view/" className={`navbar-item ${this.props.view===VIEW_SINGLE_COLUMN?"is-active":""}`} onClick={(e) => this.setNavbarKey(NAVBAR_KEY_VIEW, VIEW_SINGLE_COLUMN)}>Left Column</Link>
                    <Link to="/page-column-view-double/" className={`navbar-item ${this.props.view===VIEW_DOUBLE_COLUMN?"is-active":""}`} onClick={(e) => this.setNavbarKey(NAVBAR_KEY_VIEW, VIEW_DOUBLE_COLUMN)}>Left and Right Column</Link>
                    <Link to="/page-image-admin/" className={`navbar-item ${this.props.view===VIEW_ROW?"is-active":""}`} onClick={(e) => this.setNavbarKey(NAVBAR_KEY_VIEW, VIEW_ROW)}>Row On Top</Link>
                </a>
              </div>

*/

const mapStateToProps = state => ({
  rootdir:state.navbar[NAVBAR_KEY_ROOTDIR],
  subdir:state.navbar[NAVBAR_KEY_SUBDIR],
  orderBy:state.navbar[NAVBAR_KEY_ORDER_BY],
  view:state.navbar[NAVBAR_KEY_VIEW],
  edit:state.navbar[NAVBAR_KEY_EDIT],
})

export default connect(mapStateToProps, null)(Navbar)
