import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Background from '../images/homepage/VastraHamnenSvartVit.png'

const styles = {
  touch:{
    header:{
      backgroundImage:`url(${Background})`,
      backgroundRepeat:'no-repeat',
      backgroundSize: 'cover',
      //background: `linear-gradient(to bottom right, red, yellow)`,
      marginBottom: `1.45rem`,
      opacity:1.0
    },
    div:{
      margin: 0,
      width: '100vw',
      padding: `12.45rem 1.0875rem`,
      textAlign:'center'
    },  
  },
  desktop:{
    header:{
      backgroundRepeat:'no-repeat',
      backgroundSize: 'cover',
      background:'#F0F8FF',
      marginBottom: `1.45rem`,
      opacity:1.0
    },  
    div:{
      margin: 0,
      width: '100vw',
      padding: `1.45rem 1.0875rem`,
      textAlign:'center'
    },
  }  
}

const Header = ({ siteTitle }) => (
  <div>
    <header className = "is-hidden-touch has-text-light" style={styles.touch.header}>
      <div className="has-text-light" style={styles.touch.div} />
    </header>

    <header className = "is-hidden-desktop has-text-light" style={styles.desktop.header}>
      <div className="has-text-light" style={styles.desktop.div}>
          <Link
            to="/"
            style={{
              color:'#2b2523', 
              textDecoration: `none`,
              fontSize:30
            }}
          >
              {siteTitle}
          </Link>
      </div>
    </header>
  </div>

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
